@import "../../styles/common.scss";

.itemIcon {
  span {
    display: block;
    width: 18px;
    height: 18px;
  }
}

.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}