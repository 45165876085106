.desktop {
  width: 20rem;
  height: 100vh;
  position: relative;

  background-color: var(--body-2);

  .scrollContainer {
    padding: 2rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .logo {
    margin-bottom: 2rem;
    width: 8rem;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  &.compact {
    width: 6rem;

    .logo {
      width: 2rem;
    }
  }

  .compactToggle {
    margin: auto;
    width: 1.2rem;
    height: 2rem;
    bottom: 1rem;
    right: -1.2rem;
    position: absolute;
    z-index: 1;
    
    cursor: pointer;
    color: var(--text);
    font-size: 0.8rem;
    background-color: transparent;
    border: 1px solid var(--border);
    border-radius: 0 0.5rem 0.5rem 0;

    &:hover {
      color: var(--text);
    }
  }
}