.menuItem {
  margin: 0 -0.5rem 0.5rem -0.5rem;
  padding: 1.5rem 1rem;
  height: 2rem;
  display: flex;
  flex-shrink: 0;
  align-items: center;

  cursor: pointer;
  border-radius: 0.5rem;

  @media screen and (min-width: 1200px) {
    &:hover {
      background-color: var(--border);
    }
  }


  &.menuItemActive {
    background-color: var(--primary-background);
    color: var(--primary);

    .menuItemBadge {
      color: var(--border);
      background-color: var(--primary);
    }
  }

  &.menuItemSoon {
    pointer-events: none;
    opacity: .5;
  }

  .menuItemIcon {
    span {
      display: block;
      width: 18px;
      height: 18px;
    }
  }

  .menuItemName {
    margin-left: 0.8rem;
    flex: 1;
    font-size: 0.9rem;
    font-weight: bold;
  }

  .menuItemBadge {
    padding: 0.3rem 0.8rem;
    display: block;
    font-size: 0.8rem;
    font-weight: bold;
    color: var(--primary);
    background-color: var(--primary-background);
    border-radius: 0.5rem;
  }
}