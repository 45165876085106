.card {
  padding: 2rem;
  display: flex;
  align-items: center;
  position: relative;

  overflow: hidden;
  border-radius: 0.5rem;

  &:before {
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    background-image: url("./background.svg");
    background-size: contain;
  }

  &.blue {
    background-image: linear-gradient(90deg, var(--blue) 30%, var(--blue-2) 100%);
  }

  &.red {
    background-image: linear-gradient(90deg, var(--red) 30%, var(--red-2) 100%);
  }

  &.green {
    background-image: linear-gradient(90deg, var(--green) 30%, var(--green-2) 100%);
  }

  &.pink {
    background-image: linear-gradient(90deg, var(--pink) 30%, var(--pink-2) 100%);
  }

  &.yellow {
    background-image: linear-gradient(90deg, var(--yellow) 30%, var(--yellow-2) 100%);
  }

  &.orange {
    background-image: linear-gradient(90deg, var(--orange) 30%, var(--orange-2) 100%);
  }
}