.spacer {
  flex-grow: 1;
  position: relative;
}

.infoCard {
  margin: 1rem -0.5rem 2rem -0.5rem;
  height: 9rem;
  flex-shrink: 0;
  background-color: var(--blue);
  border-radius: 0.5rem;
}

@media screen and (max-width: 1400px) {
  .spacer:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 1px;
    background-color: var(--border);
  }
}