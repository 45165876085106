.mobile {
  height: 6rem;
  padding: 0 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  top: 0;
  bottom: auto;
  left: 0;
  right: 0;
  z-index: 1;

  background-color: var(--body);
  border-bottom: 1px solid var(--border);

  .button {
    color: var(--text);
    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
}