.notFound {
  padding: 3rem 0;
  width: 90%;
  max-width: 400px;
  margin: 0 auto;

  .footnote {
    margin-top: 2rem;
    display: block;
    font-size: 0.8rem;

    a {
      color: var(--text);
    }
  }
}