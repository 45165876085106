.layout {
  min-height: 100vh;
  display: flex;

  @media screen and (min-width: 1200px) {
    flex-direction: row;

    .content {
      flex: 1;
      height: 100vh;
      overflow: auto;
    }
  }

  @media screen and (max-width: 1200px) {
    padding-top: 6rem;
    flex-direction: column;
  }

  .content {
    padding: 2rem;
  }
}