.error {
  padding: 2rem 0;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;

  pre {
    word-wrap: break-word;
    overflow: auto;
  }

  .footnote {
    margin-top: 2rem;
    display: block;
    font-size: 0.8rem;

    a {
      color: var(--text);
    }
  }
}