@import "./colors.scss";

html,
body {
  margin: 0;
  font-family: Poppins, sans-serif;
  background-color: var(--body);
  color: var(--text);
}

* {
  box-sizing: border-box;
}

input,
textarea,
button,
select {
  font-family: Poppins, sans-serif !important;
}

[hidden] {
  display: none !important;
}

[class*="ant-btn"] {
  font-weight: bold;
}

[class*="ant-btn-ghost"] {
  color: var(--text);
}

[data-theme="light"] {
  [class*="ant-btn-default"][class*="ant-btn-dangerous"] {
    background-color: transparent;
  }
}

[data-theme="dark"] [class*="ant-btn-default"] {
  color: var(--text);
  border-color: var(--border);
  background-color: var(--body-2);

  &:disabled {
    color: var(--text-2);
    opacity: 0.8;
    cursor: not-allowed;
  }

  &[class*="ant-btn-dangerous"] {
    background-color: var(--pink);
    border-color: var(--pink);
    color: white;
  }
}

small {
  opacity: 0.8;
}

input[class*="ant-input"] {
  font-weight: bold;

  &::placeholder {
    opacity: 0.5;
    font-weight: bold;
  }
}

[class*="ant-select"] {
  font-weight: bold;

  [class*="ant-select-arrow"] {
    color: var(--text);
  }

  [class*="ant-select-selection-item"] {
    font-weight: bold;
  }

  [class*="ant-select-item-option-selected"] {
    color: var(--blue) !important;
  }
}

[class*="ant-select-dropdown"] {
  border: 1px solid var(--border);
}

[data-theme="dark"] [class*="ant-select-multiple"] [class*="ant-select-selection-item"] {
  background-color: #fafafa !important;
  color: #424242;
}

[data-theme="dark"] [class*="ant-input"] {
  color: var(--text) !important;

  &:not(:focus) {
    border-color: var(--border) !important;
  }
}

[data-theme="dark"] [class*="ant-input-search"] {
  background-color: var(--body-2);
}

[class*="ant-input-search"] {
  border-radius: 0.5rem;

  svg {
    color: var(--text-2);
  }

  [class*="ant-input"] {
    background-color: transparent !important;
  }

  [class*="ant-input-group-addon"] button {
    border-radius: 0 0.5rem 0.5rem 0;
    border-color: var(--border);
    background: transparent;
  }
}

[class*="ant-divider"] {
  border-color: var(--border);
}

[class*="ant-tabs"] [class="ant-tabs-nav"] {
  &:before {
    background-color: var(--border);
  }

  [class*="ant-tabs-tab"] {
    margin: 0 !important;
  }

  [role="tab"] {
    padding: 0 2rem;

    color: var(--text);
    font-size: 1rem;
    font-weight: bold;

    &:not([aria-selected="true"]) a {
      color: var(--text);
    }
  }

  [class*="ant-tabs-tab-disabled"] [role="tab"] {
    color: var(--border);
    pointer-events: none;

    a {
      color: var(--border);
    }
  }
}

[class*="ant-list-bordered"] {
  border-color: var(--border);

  [class*="ant-list-header"] {
    border-color: var(--border);
  }
}

[class*="ant-tag"] {
  padding: 0 1rem;
  height: 1.5rem;

  font-size: 0.7rem;
  font-weight: bold;
  line-height: 1.4rem;
  border-radius: 1rem;
}

[class*="ant-card-bordered"] {
  border-color: var(--border);

  [class*="ant-card-head"] {
    border-color: var(--border);
  }
}

[data-theme="dark"] [class*="ant-card-bordered"] [class*="ant-card-head"] {
  background-color: #232930;
}

[data-theme="dark"] table {
  background-color: var(--body-2);
}

table {
  border: 1px solid var(--border);
  border-radius: 1rem;
  overflow: hidden;

  [class*="ant-table-selection"] {
    padding: 0 1rem;
  }

  [class*="ant-table-thead"] {
    background-color: transparent;
    border-color: transparent;

    [class*="ant-table-cell"] {
      color: var(--text);
      font-weight: bold;
      background-color: transparent !important;
      border-bottom: 1px solid var(--border) !important;

      &::before {
        background-color: var(--border) !important;
      }
    }
  }

  [class*="ant-table-tbody"] {
    [class*="ant-table-cell"] {
      border-color: var(--border) !important;
    }

    [class*="ant-table-row"]:last-child td {
      border-bottom: 0 !important;
    }
  }

  [class*="ant-table-cell-row-hover"] {
    border-radius: 0 !important;
    background: rgba(12, 12, 12, .05) !important;
  }

  [class*="ant-table-row-selected"] [class*="ant-table-cell"] {
    border-radius: 0 !important;
    background: var(--primary-background) !important;
  }

  [class*="ant-empty-description"] {
    color: var(--text-2);
  }

  [class*="ant-pagination-item-link"] svg {
    color: var(--text-2);
  }

  [class*="ant-checkbox-inner"] {
    width: 1.2rem;
    height: 1.2rem;
    background-color: transparent;
    border-color: var(--border);
  }

  [class*="ant-checkbox-wrapper-checked"] [class*="ant-checkbox-inner"] {
    background-color: var(--blue) !important;
  }

  [class*="ant-pagination-item"] {
    background-color: transparent;
  }
}

.svgIcon {
  width: 0.8rem;
  height: 0.8rem;
}