:root {
  --body: #fff;
  --border: #ddd;
  --text: #212121;
  --text-2: #919191;
  --blue: #0C4FFF;
  --blue-2: #0732A1;
  --blue-3: #EAF1FF;
  --green: #1ac938;
  --green-2: #08ad21;
  --red: #FF0C0C;
  --red-2: #A10707;
  --pink: #FF1B6B;
  --pink-2: #D20E53;
  --pink-3: #FFE9E9;
  --yellow: #cad030;
  --yellow-2: #beaf0f;
  --orange: #d08b30;
  --orange-2: #be7e0f;
  --primary: var(--blue);
  --primary-background: var(--blue-3);
  --accent: var(--pink);
  --accent-background: var(--pink-3);
}

[data-theme="dark"] {
  --body: #0d1520;
  --border: #313231;
  --text: #fafafa;
  --text-2: #afb4bd;
  --primary: var(--blue-3);
  --primary-background: var(--blue);
  --body-2: rgba(200, 200, 200, 0.1);
}

[data-theme="light"] {
  --body: #fff;
  --border: #eee;
  --text: #212121;
  --text-2: #919191;
  --primary: var(--blue);
  --primary-background: var(--blue-3);
  --body-2: rgba(100, 100, 100, 0.05);
}