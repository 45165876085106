[data-theme="dark"] .statisticsCard.disabled {
  background-color: #323232;
}

.statisticsCard {
  padding: 1.5rem 1rem;
  margin-bottom: 1rem;
  background-color: var(--body-2);
  border-radius: 0.5rem;
  font-weight: bold;

  &.disabled {
    background-color: #eee;

    .title {
      color: #ccc;
    }

    .content .data {
      color: #aaa;
    }
  }

  .title {
    margin: 0;
    font-size: 1rem;
    color: var(--text-2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .content {
    display: flex;
    align-items: center;

    .data {
      margin-right: 0.6rem;
      color: var(--text);
      font-size: 2rem;
    }

    .variation {
      padding: 0.2rem 0.6rem;
      display: block;

      color: #fafafa;
      font-size: 0.8rem;
      border-radius: 0.5rem;

      .icon {
        margin-right: 0.3rem;

        svg {
          width: 0.7rem;
          height: 0.7rem;
        }
      }

      &.up {
        background-color: var(--green);
      }

      &.down {
        background-color: var(--pink);
      }

      &.stable {
        background-color: #919191;
      }
    }
  }

  .children {
    font-weight: normal;
  }
}