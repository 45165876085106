section {
  margin-bottom: 2rem;
}

.outlet {
  padding: 1rem;
}

h1.title {
  margin: 0;
  margin-bottom: 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

::placeholder {
  color: rgba(255, 255, 255, .5) !important;
}

h2.title {
  margin: 0;
  color: var(--text-2);
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: bold;
}

[class*="ant-breadcrumb"] {
  font-size: 1.5rem;
  color: var(--text) !important;

  a {
    font-size: 1.5rem;
    color: var(--blue) !important;
    font-weight: bold;
    height: auto !important;
  }

  h1.title {
    margin: 0;
  }

  [class="ant-breadcrumb-separator"] {
    color: var(--border) !important;
    font-weight: bold;
  }
}