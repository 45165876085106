@import "../../styles/common.scss";

.home {

  a {
    color: inherit;
    text-decoration: none;
  }

  .link {
    margin-bottom: 1rem;
  }
}