.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    opacity: .8;
  }

  span {
    color: var(--text-2);
  }

  &[data-size="small"] {
    svg {
      margin: 1rem 0;
      width: 3rem;
    }
  }

  &[data-size="medium"] {
    svg {
      margin: 1.5rem 0;
      width: 5rem;
    }
  }

  &[data-size="large"] {
    svg {
      margin: 2rem 0;
      width: 8rem;
    }
  }
}